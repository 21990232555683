<template>
    <router-view v-bind="attributes" v-on="listeners" />
</template>

<script>
export default {
    computed: {
        attributes() {
            return {
                ...this.$attrs,
                // key: this.$route.fullPath,
            };
        },
        listeners() {
            return this.$listeners;
        },
    },
};
</script>
